<template>
  <div class="x-menu" :class="[!isShow && 'hidden']" v-if="!data.hidden">
    <div class="x-menu-line-left"></div>
    <div class="menu-header select-none">
      <div class="menu-unfold flex-center pointer" @click="isShow = !isShow">
        <div class="menu-unfold-border flex-center">
          {{ !isShow ? "+" : "-" }}
        </div>
      </div>
      <div class="menu-unfold-line"></div>
      <img class="menu-folder-icon" src="../../assets/icons/menu.png" alt="" />
      <!-- <img
        class="menu-folder-icon"
        v-else
        src="../../assets/icons/file.png"
        alt=""
      /> -->
      <div class="pointer menu-title" @click="goToPage">
        {{ getTitle }}
      </div>
    </div>
    <div v-if="routeList.length > 0" class="menu-children">
      <XMenuItem v-for="(menu, index) in routeList" :key="index" :data="menu" />
    </div>
  </div>
</template>

<script>
import XMenuItem from "@/components/XMenu/XMenuItem.vue";

export default {
  name: "XMenu",
  components: { XMenuItem },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    routeList() {
      if (this.data.children && this.data.children.length > 0) {
        return this.data.children.filter((item) => {
          return item.hidden !== true;
        });
      } else {
        return [];
      }
    },
    getTitle() {
      return this.data.meta?.title || this.data.children[0].meta?.title || "";
    },
  },
  methods: {
    // 点击菜单
    goToPage() {
      // console.log(333, this.data.path);
      this.isShow = !this.isShow;
      // this.$router.push({
      //   path: this.data.path,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.x-menu {
  //   border-left: 1px dotted rgba(0, 0, 0, 0.2);
  //   padding-bottom: 30px;
  line-height: 36px;
  position: relative;
  overflow: hidden;
  margin-top: 1px;

  &.hidden {
    height: 36px;
  }

  .x-menu-line-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7px;
    border-left: 2px dotted rgba(0, 0, 0, 0.2);
    z-index: 0;
  }

  .menu-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    .menu-title {
      font-size: 14px;
      font-weight: 600;
    }

    .menu-unfold {
      width: 16px;
      height: 18px;
      background-color: #fff;
      position: relative;
      //   margin-left: -8px;
      z-index: 2;

      .menu-unfold-border {
        width: 12px;
        height: 12px;
        background: linear-gradient(
          180deg,
          rgba(217, 217, 217, 0) 0%,
          #d9d9d9 100%
        );
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
        //   margin-left: -6px;
        position: relative;
        line-height: 10px;
        padding-bottom: 2px;
      }
    }

    .menu-folder-icon {
      width: 24px;
      object-fit: cover;
      margin-right: 2px;
    }

    .menu-unfold-line {
      height: 0;
      width: 8px;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
      border-width: 2px;
    }
  }

  .menu-children {
    margin-left: 44px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -6px;
      width: 12px;
      height: 20px;
      bottom: 0;
      background-color: white;
    }
  }

  &:last-child::after {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    width: 12px;
    // height: 12px;
    top: 25px;
    bottom: 0;
    background-color: white;
  }
}
</style>
