import request from "@/utils/request";

export function getList(params) {
  return request({
    url: "/admin/menu/index",
    method: "get",
    params,
  });
}

// 添加菜单
export function addMenu(data) {
  return request({
    url: "/admin/menu/add",
    method: "post",
    data,
  });
}

// 修改菜单
export function editMenu(data) {
  return request({
    url: "/admin/menu/edit",
    method: "put",
    data,
  });
}

// 删除菜单
export function delMenu(id) {
  return request({
    url: "/admin/menu/delete",
    method: "delete",
    params: { id },
  });
}

// 删除菜单
export function menuTree(params) {
  return request({
    url: "/admin/menu/tree",
    method: "get",
    params,
  });
}
