<template>
  <div class="sidebar-container" v-if="!item.hidden">
    <template v-if="hasOneShowingChild(item.children, item)">
      <div @click="clickMenu(onlyOneRoute)">
        <el-menu-item :index="onlyOneRoute.path">
          <!-- <img
            v-if="onlyOneRoute.icon"
            :src="
              require(`../../assets/icons/menu-icons/${
                onlyOneRoute.meta && onlyOneRoute.meta.icon
              }.png`)
            "
            class="icon-img"
            alt=""
          /> -->
          <XIcon
            v-if="onlyOneRoute.meta && onlyOneRoute.meta.icon"
            :name="onlyOneRoute.meta && onlyOneRoute.meta.icon"
            :size="20"
          />
          <span style="margin-left: 10px">
            {{ onlyOneRoute.meta && onlyOneRoute.meta.title }}
          </span>
        </el-menu-item>
      </div>
    </template>
    <el-submenu v-else :index="item.path">
      <template slot="title">
        <!-- <img
          v-if="onlyOneRoute.icon"
          :src="
            require(`../../assets/icons/menu-icons/${
              item.meta && item.meta.icon
            }.png`)
          "
          class="icon-img"
          alt=""
        />
        <i v-else class="el-icon-menu"></i> -->
        <XIcon
          v-if="item.meta && item.meta.icon"
          :name="item.meta && item.meta.icon"
          :size="20"
        />
        <span slot="title" style="margin-left: 10px">
          {{ item.meta && item.meta.title }}
        </span>
      </template>
      <Sidebar
        v-for="child in item.children"
        :key="child.name"
        :is-nest="true"
        :item="child"
      />
    </el-submenu>
  </div>
</template>

<script>
import XMenu from "@/components/XMenu/index.vue";
import Sidebar from "./Sidebar.vue";
import { XIcon } from "@/components/XIconSelect";

export default {
  name: "Sidebar",
  components: { XMenu, Sidebar, XIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onlyOneRoute: {},
    };
  },
  methods: {
    /* 选中菜单 */
    clickMenu(item) {
      this.$router.push({ path: item.path });
      // console.log("item", item);
      // if
      this.$store.commit("SET_CURRENT_ROUTE", item);
    },
    hasOneShowingChild(children = [], parent) {
      // 白名单
      const whiteList = ["/statistics/home"];
      /* 过滤掉隐藏的菜单 */
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          return true;
        }
      });
      /* 如果子菜单存在只有一个并且是白名单 */
      if (
        showingChildren.length === 1 &&
        whiteList.includes(showingChildren[0].path)
      ) {
        this.onlyOneRoute = showingChildren[0];
        return true;
      }

      if (parent.component?.name === "layout-view") {
        this.onlyOneRoute = parent;
        return true;
      }

      if (showingChildren.length === 0) {
        this.onlyOneRoute = parent;
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  width: 100%;

  .icon-img {
    width: 20px;
  }
}
</style>
