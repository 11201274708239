import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout/index.vue";

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const originReplace = Router.prototype.replace;
Router.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/index.vue"),
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  // {
  //   path: "/",
  //   component: Layout,
  //   redirect: "/home",
  //   children: [
  //     {
  //       path: "/home",
  //       name: "home",
  //       component: () => import("@/views/home/index"),
  //       meta: { title: "欢迎页", icon: "home" },
  //     },
  //   ],
  // },
  {
    path: "/common",
    component: Layout,
    redirect: "/editorOnline",
    children: [
      {
        path: "/common/editorOnline",
        name: "editorOnline",
        component: () => import("@/views/common/editorOnline/index.vue"),
        meta: { title: "在线编辑", icon: "home" },
      },
    ],
  },
  // 温控大屏
  {
    path: "/temperatureLargeScreen",
    name: "temperatureLargeScreen",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/temperatureLargeScreen/index.vue"
      ),
  },
  {
    path: "/largeScreen",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/largeScreen/index.vue"),
  },
  {
    path: "/bigScreen",
    component: () => import("@/views/BigScreen/index.vue"),
    props: (route) => ({ current: route.query.current }),
  },
];

const createRouter = () =>
  new Router({
    mode: "hash", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
