import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/calculate"
import "@/styles/index.scss"; // global css

import "@/icons"; // icon
import "@/permission"; // permission control
import "@/directives"; // 自定义指令

import "@/components/XLayout"; //布局组件
import XTable from "@/components/XTable"; // 表格组件组件
import XPaging from "@/components/XPaging"; // 分页组件组件

Vue.component("XTable", XTable);
Vue.component("XPaging", XPaging);

Vue.config.productionTip = false;
Vue.use(ElementUI);

// console.log("当前环境：", process.env.VUE_APP_ENV);
//  development/production 屏蔽所有console
if (
  process.env.VUE_APP_ENV === "production" ||
  process.env.VUE_APP_ENV === "staging"
) {
  console.log = function () {};
  console.warn = function () {};
  // console.error = function () {};
  console.info = function () {};
  // console.debug = function () {};
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
