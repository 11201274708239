<template>
  <div style="position: relative">
    <div class="menu-item-line"></div>
    <div class="x-menu-item select-none">
      <img class="menu-file-icon" src="../../assets/icons/file.png" alt="" />
      <div class="menu-item-title pointer" @click="goToPage">
        {{ data.meta.title }}
      </div>
    </div>
    <!-- <div></div> -->
    <div style="margin-left: 28px" v-if="routeList.length > 0">
      <XMenuItem v-for="(item, index) in routeList" :key="index" :data="item" />
    </div>
  </div>
</template>

<script>
import XMenuItem from "./XMenuItem.vue";

export default {
  name: "XMenuItem",
  components: { XMenuItem },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          meta: {
            title: "二级菜单",
          },
        };
      },
    },
  },
  computed: {
    routeList() {
      if (this.data.children && this.data.children.length > 0) {
        return this.data.children.filter((item) => {
          return item.hidden !== true;
        });
      } else {
        return [];
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    // 点击菜单
    goToPage() {
      this.$router.push({
        name: this.data.name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item-line {
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin-top: 1px;
  border-left: 2px dotted rgba(0, 0, 0, 0.2);
}
.x-menu-item {
  display: flex;
  align-items: center;
  line-height: px;
  gap: 4px;
  position: relative;
  line-height: 42px;

  &::before {
    content: "";
    width: 16px;
    display: block;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.2);
  }

  .menu-file-icon {
    width: 24px;
    object-fit: cover;
    margin-right: 2px;
  }

  .menu-item-title {
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
