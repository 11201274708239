// import { getRoute, getPermission } from "@/utils/auth";
// import { constantRoutes } from "@/router";
import Layout from "@/layout";
import LayoutView from "@/layout/layout-view";
import { roleMenuList } from "@/api/rights/role";
import { getList } from "@/api/system/menu";
import { resetRouter } from "@/router";
import router from "@/router";
import { Message } from "element-ui";
import LayoutBlank from "@/layout/layout-blank.vue";

const getRouteHistory = () => {
  let list = [];
  let routeHistory = localStorage.getItem("routeHistory");
  return routeHistory ? JSON.parse(routeHistory) : list;
};

const getCurrentRoute = () => {
  let route = localStorage.getItem("currentRoute");
  return route ? JSON.parse(route) : {};
};

const getLayoutViewList = (item) => {
  let route = localStorage.getItem("layoutViewRoute");
  return route ? JSON.parse(route) : {};
};

const getDefaultState = () => {
  return {
    routeList: [],
    routeHistory: getRouteHistory(),
    route: [],
    permission: [],
    currentRoute: getCurrentRoute(),
    /**  */
    layoutViewRoute: getLayoutViewList(),
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE_MAIN: (state) => {
    localStorage.removeItem("permission");
    localStorage.removeItem("routeHistory");
    localStorage.removeItem("currentRoute");
    localStorage.removeItem("layoutViewRoute");
    Object.assign(state, getDefaultState());
  },
  SET_ROUTE_LIST: (state, route) => {
    state.routeList = route;
  },
  SET_ROUTE: (state, route) => {
    console.log("路由", route);
    state.route = route;
  },
  SET_PERMISSION: (state, permission) => {
    localStorage.setItem("permission", JSON.stringify(permission));
    state.permission = permission;
  },

  SET_HISTORY: (state, route) => {
    if (route.name === "home") return;
    if (state.routeHistory.some((v) => v.path === route.path)) return;
    state.routeHistory.push(
      Object.assign({}, route, {
        title: route.meta.title || "no-name",
      })
    );
    localStorage.setItem("routeHistory", JSON.stringify(state.routeHistory));
  },
  DEL_HISTORY: (state, index) => {
    state.routeHistory.splice(index, 1);
  },
  CLEAR_HISTORY: (state) => {
    state.routeHistory = [];
  },
  DEL_LEFT: (state, index) => {
    state.routeHistory = state.routeHistory.filter((v, num) => {
      return num >= index;
    });
    localStorage.setItem("routeHistory", JSON.stringify(state.routeHistory));
  },
  DEL_RIGHT: (state, index) => {
    state.routeHistory = state.routeHistory.filter((v, num) => {
      return num <= index;
    });
    localStorage.setItem("routeHistory", JSON.stringify(state.routeHistory));
  },
  DEL_OTHER: (state, route) => {
    state.routeHistory = [route];
    localStorage.setItem("routeHistory", JSON.stringify(state.routeHistory));
  },
  DEL_ALL: (state, index) => {
    state.routeHistory = [state.routeHistory[index]];
    localStorage.setItem("routeHistory", JSON.stringify(state.routeHistory));
  },
  SET_CURRENT_ROUTE: (state, route) => {
    localStorage.setItem("currentRoute", JSON.stringify(route));
    state.currentRoute = route;
  },
  SET_LAYOUT_VIEW_ROUTE: (state, route) => {
    localStorage.setItem("layoutViewRoute", JSON.stringify(route));
    state.layoutViewRoute = route;
  },
};

const actions = {
  // 根据角色获取路由
  async getRoutes({ rootGetters, commit, dispatch }) {
    let userInfo = rootGetters.userInfo;

    let menus = await getList({ noauth: true });
    let routeList = menus.data;
    let userMenus = [];
    if (userInfo.id === 1) {
      // 管理员 查询所有菜单
      userMenus = routeList;
    } else {
      let rel = await roleMenuList({ id: userInfo.role.id, noauth: true });
      userMenus = routeList.filter((v) => {
        return rel.data.includes(v.id);
      });
    }
    if (userMenus.length === 0) {
      Message.error("该角色没有菜单权限，请联系管理员");
      resetRouter();
      commit("RESET_STATE", {}, { root: true });
      commit("RESET_STATE_MAIN", {}, { root: true });
      return;
    }
    let userRoutes = userMenus.filter((item) => item.type != "F");
    let permission = userMenus
      .filter((item) => item.type == "F")
      .map((item) => item.perms);
    commit("SET_ROUTE_LIST", userRoutes);
    commit("SET_PERMISSION", permission);
    await dispatch("GenerateRoutes", userMenus);
  },
  GenerateRoutes({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_LAYOUT_VIEW_ROUTE", []);
      let routes = getRouteTree(state.routeList);
      // routes.push({ path: "*", redirect: "/404", hidden: true });
      // commit("SET_ROUTE_STATUS", true);
      resetRouter();
      commit("SET_ROUTE", routes);
      routes.forEach((item) => {
        router.addRoute(item);
      });
      router.addRoute({
        path: "/",
        redirect:
          routes[0].children.length > 0
            ? routes[0].children[0].path
            : routes[0].path,
        hidden: true,
      });
      router.addRoute({ path: "*", redirect: "/404", hidden: true });
      resolve(routes);
    });
  },

  // 删除历史记录
  delHistory({ commit, state }, path) {
    let index = state.routeHistory.findIndex((v) => v.path === path);
    commit("DEL_HISTORY", index);
  },
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
};

//
export const getRouteName = ({ name, id }) => {
  /* 生成随位随机字母 */

  let newName = name.split("?")[0].replace(/\//g, "-").substr(1);
  return newName + id;
};

/* 获取 component  */

export const getLayoutView = (item) => {
  const routerView = {
    layout: Layout,
    "layout-view": LayoutView,
    "layout-blank": LayoutBlank,
  };

  if (item.type == "M") {
    return routerView[item.component];
  } else if (item.type == "C") {
    return loadView(item.component);
  }
};

export const getRouteTree = (list, parent_id = 0, active = "") => {
  let arr = [];
  list.forEach((item) => {
    let route = {};
    if (item.parent_id == parent_id) {
      route = {
        path: item.url.split("?")[0],
        component: getLayoutView(item),
        name: getRouteName({ name: item.url, id: item.id }),
        hidden: item.status == "0" ? true : false, // 状态
        listsort: item.listsort, // 排序
        meta: {
          title: item.name,
          icon: item.icon,
          menu_id: item.id,
          activeMenu: active,
        },
      };

      arr.push(route);
      let activeMenu = item.component == "layout-view" ? route.path : "";
      route.children = getRouteTree(list, item.id, activeMenu) || [];
      // console.log("route", item.name, item.type, item);
      // console.log("state", state);
      if (item.component === "layout-view") {
        const { layoutViewRoute } = state;
        const { SET_LAYOUT_VIEW_ROUTE } = mutations;
        SET_LAYOUT_VIEW_ROUTE(state, [...layoutViewRoute, route]);
      }

      if (item.type == "M" && route.children?.length) {
        route.redirect = route.children[0].path;
      }
    }
  });
  arr = arr.sort((a, b) => {
    return a.listsort - b.listsort;
  });
  return arr;
};

export const loadView = (url) => {
  let view = url.split("?")[0];
  if (process.env.NODE_ENV === "development") {
    return (resolve) => require([`@/views${view}`], resolve);
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views${view}`);
  }
};
