const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  route: state => state.main.route,
  routeList: state => state.main.routeList,
  routeHistory: state => {
    return [
      // {
      //   path: "/home",
      //   name: "home",
      //   meta: { title: "欢迎页", icon: "home" },
      // },
      ...state.main.routeHistory,
    ];
  },
  permission: state => state.main.permission,
  msgUnReadNum: state => state.user.msgUnReadNum,
  currentRoute: state => state.main.currentRoute,
  layoutViewRoute: state => state.main.layoutViewRoute,
};
export default getters;
