import Vue from "vue";
const directives = require.context("./", true, /\.js$/);

directives.keys().forEach((it) => {
  const directive = directives(it);
  const newKey = it.replace(/^\.\/|\.ts|\.js/g, "");
  if (newKey != "index") {
    Vue.directive(newKey, directive.default);
  }
});
// Vue.directive("throttle", throttle);
