import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/admin/login",
    method: "post",
    data,
  });
}

export function logout() {
  return request({
    url: "/admin/logout",
    method: "get",
  });
}

// 消息列表
export function messageList(params) {
  return request({
    url: "/admin/notice_message/index",
    method: "get",
    params: Object.assign({ is_delete: 0 }, params),
  });
}

// 消息已读
export function messageRead(id) {
  return request({
    url: "/admin/notice_message/edit",
    method: "put",
    params: { id },
  });
}

// 消息删除
export function messageDelete(id) {
  return request({
    url: "/admin/notice_message/delete",
    method: "delete",
    params: { id },
  });
}

// 消息/公告已读
export function messageEditRead(data) {
  return request({
    url: "/admin/notice_message/edit_read",
    method: "put",
    data,
  });
}

// 修改密码
export function editPassword(data) {
  return request({
    url: "/admin/user/edit_password",
    method: "put",
    data,
  });
}

// 未读信息
export function messageUnread() {
  return request({
    url: "/admin/notice_message/read_num",
    method: "get",
  });
}

// 个人信息
export function userInfo(params) {
  return request({
    url: "/admin/user/member_info",
    method: "get",
    params,
  });
}

// 修改个人信息
export function editUserInfo(data) {
  return request({
    url: "/admin/user/edit_member",
    method: "put",
    data,
  });
}
