<template>
  <div class="app-wrapper">
    <navbar />
    <div class="sidebar-main-container">
      <!-- <sidebar /> -->
      <Aside />
      <app-main />
    </div>
    <x-message ref="xMessage" />
    <x-notice ref="xNotice" />
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, Aside } from "./components";
import { mapGetters } from "vuex";
import XMessage from "@/components/XMessage";
import XNotice from "@/components/XNotice";
// import { MessageBox } from "element-ui";

export default {
  name: "Layout",
  components: {
    Navbar,
    Aside,
    Sidebar,
    AppMain,
    XMessage,
    XNotice,
  },
  data() {
    return {
      // websocket相关
      socketObj: "", // websocket实例对象
      //心跳检测
      heartCheck: {
        vueThis: this, // vue实例
        timeout: 5000, // 超时时间
        timeoutObj: null, // 计时器对象——向后端发送心跳检测
        serverTimeoutObj: null, // 计时器对象——等待后端心跳检测的回复
        // 心跳检测重置
        reset: function () {
          clearTimeout(this.timeoutObj);
          clearTimeout(this.serverTimeoutObj);
          return this;
        },
        // 心跳检测启动
        start: function () {
          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(() => {
            // 这里向后端发送一个心跳检测，后端收到后，会返回一个心跳回复
            this.vueThis.socketObj.send(
              JSON.stringify({
                type: "ping",
              })
            );
            // console.log("发送心跳检测");
            this.serverTimeoutObj = setTimeout(() => {
              // 如果超过一定时间还没重置计时器，说明websocket与后端断开了
              console.log("未收到心跳检测回复");
              // 关闭WebSocket
              this.vueThis.socketObj && this.vueThis.socketObj.close();
            }, this.timeout);
          }, this.timeout);
        },
      },
      socketReconnectTimer: null, // 计时器对象——重连
      socketReconnectLock: false, // WebSocket重连的锁
      socketLeaveFlag: false, // 离开标记（解决 退出登录再登录 时出现的 多次相同推送 问题，出现的本质是多次建立了WebSocket连接）
      /**
       * 版本号
       * 由于用户的表格config都缓存起来了，当表格的config被修改时，需要清除旧的缓存，否则无法及时更新表格的config
       * 所以这里采用版本号，当版本号不一致时，会弹出更新提示，同意时清除所有localStorage，并存入新的版本号
       */
      // version: "0.0.3",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    console.log("离开标记", this.socketLeaveFlag);
  },
  mounted() {
    this.createWebSocket();
    // let version = localStorage.getItem("version");
    // if (!version) {
    //   localStorage.setItem("version", this.version);
    // } else if (version && version !== this.version) {
    //   MessageBox.confirm("版本已更新，请重新登录", "版本更新", {
    //     confirmButtonText: "重新登录",
    //     showCancelButton: false,
    //     type: "warning",
    //   }).then(() => {
    //     this.$store.dispatch("tokenPast").then(() => {
    //       this.$router.push({ path: "/login" });
    //     });
    //     localStorage.clear();
    //     localStorage.setItem("version", this.version);
    //   });
    // }
  },
  destroyed() {
    // 离开标记
    this.socketLeaveFlag = true;
    // 关闭WebSocket
    this.socketObj && this.socketObj.close();
    // this.heartCheck.reset()
  },
  methods: {
    // websocket启动
    createWebSocket() {
      // let webSocketLink = "ws://192.168.0.108:8386";
      // let webSocketLink = "ws://test3.whxzs.net:8282";
      // let webSocketLink = "wss://test3.whxzs.net/websocket";
      let webSocketLink = process.env.VUE_APP_WS;

      // console.log(webSocketLink);
      try {
        if ("WebSocket" in window) {
          this.socketObj = new WebSocket(webSocketLink);
        } else if ("MozWebSocket" in window) {
          this.socketObj = new MozWebSocket(webSocketLink);
        }
        // websocket事件绑定
        this.socketEventBind();
      } catch (e) {
        console.log("catch" + e);
        // websocket重连
        this.socketReconnect();
      }
    },
    // websocket事件绑定
    socketEventBind() {
      // 连接成功建立的回调
      this.socketObj.onopen = this.onopenCallback;
      // 连接发生错误的回调
      this.socketObj.onerror = this.onerrorCallback;
      // 连接关闭的回调
      this.socketObj.onclose = this.oncloseCallback;
      // 向后端发送数据的回调
      this.socketObj.onsend = this.onsendCallback;
      // 接收到消息的回调
      this.socketObj.onmessage = this.getMessageCallback;

      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = () => {
        this.socketObj && this.socketObj.close();
      };
    },
    // websocket重连
    socketReconnect() {
      if (this.socketReconnectLock) {
        return;
      }
      this.socketReconnectLock = true;
      this.socketReconnectTimer && clearTimeout(this.socketReconnectTimer);
      this.socketReconnectTimer = setTimeout(() => {
        console.log("WebSocket:重连中...");
        this.socketReconnectLock = false;
        // websocket启动
        this.createWebSocket();
      }, 4000);
    },
    // 连接成功建立的回调
    onopenCallback: function (event) {
      console.log("WebSocket:已连接");
      this.socketObj.send(
        JSON.stringify({
          type: "bind",
          admin_id: this.userInfo.id,
        })
      );
      // // 心跳检测重置
      this.heartCheck.reset().start();
    },
    // 连接发生错误的回调
    onerrorCallback: function (event) {
      console.log("WebSocket:发生错误");
      // websocket重连
      this.socketReconnect();
    },
    // 连接关闭的回调
    oncloseCallback: function (event) {
      console.log("WebSocket:已关闭");
      // 心跳检测重置
      this.heartCheck.reset();
      if (!this.socketLeaveFlag) {
        // 没有离开——重连
        // websocket重连
        this.socketReconnect();
      }
    },
    // 向后端发送数据的回调
    onsendCallback: function () {
      console.log("WebSocket:发送信息给后端");
    },
    // 接收到消息的回调
    getMessageCallback: function (msg) {
      // console.log(msg);
      let data = JSON.parse(msg.data);
      if (data.type == "ping") {
        // console.log("收到心跳检测回复", msg.data);
        this.heartCheck.reset().start();
        return;
      }
      // 查询未读消息
      this.$store.dispatch("messageUnread");
      switch (data.type) {
        case "notice":
          console.log("收到公告", data);
          this.$refs.xNotice.open(data);
          break;
        case "inform":
          console.log("收到通知", data);
          this.$refs.xMessage.add(data);
          break;
        case "remind":
          console.log("收到提醒", data);
          this.$refs.xMessage.add(data);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;

  .sidebar-main-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    height: calc(100% - 56px);
  }
}
</style>
