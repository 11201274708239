<template>
  <div id="x-message">
    <div
      class="message-item"
      v-for="(item, index) in msgList"
      :key="index"
      :class="[!item.show && 'hidden']"
      :style="{ zIndex: index }"
      @animationend="animationend(item, index)"
    >
      <div class="item-header">
        <img
          src="@/assets/icons/tip-icon.png"
          class="header-icon"
          alt=""
          srcset=""
        />
        <div class="header-title">{{ item.title }}</div>
        <div class="item-msg-num">{{ msgList.length }}条未读消息</div>
      </div>
      <div class="item-content" v-html="item.content"></div>
      <div class="item-footer">
        <button class="search-button" @click="confirm(item, index)">
          确定
        </button>
        <!-- <button class="reset-button" @click="cancel">取消</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { messageEditRead, messageRead } from "@/api/user";

const getMessage = () => {
  let message = localStorage.getItem("message");
  if (message) {
    return JSON.parse(message);
  } else {
    return [];
  }
};

export default {
  name: "XMessage",
  data() {
    return {
      msgList: getMessage(),
    };
  },
  computed: {
    ...mapGetters(["userInfo", "msgUnReadNum"]),
  },
  watch: {
    msgUnReadNum(val) {
      this.refresh();
    },
  },
  methods: {
    // 添加消息
    add(msg) {
      this.msgList.push({ ...msg, show: true });
      //   this.scrollToBottom();
      localStorage.setItem("message", JSON.stringify(this.msgList));
    },
    // 清空消息
    async confirm(value, index) {
      //   this.msgList = [];
      //   this.msgList.splice(index, 1);
      try {
        let data = {
          notice_id: this.msgList[index].id,
          admin_id: this.$store.getters.userInfo.id,
        };
        if (this.msgList[index].type == "remind") {
          await messageRead(this.msgList[index].id);
        } else {
          await messageEditRead(data);
        }
        this.msgList[index].show = false;
      } catch (err) {
        this.$message.error(err.msg);
      }
    },
    // 刷新
    refresh() {
      console.log("刷新");
      let list = localStorage.getItem("message");
      if (list) this.msgList = JSON.parse(list) || [];
    },
    scrollToBottom() {
      var container = document.querySelector("#x-message");
      container.scrollTop = container.scrollHeight;
      console.log(container);
    },
    animationend(value, index) {
      if (!value.show) {
        this.msgList.splice(index, 1);
      }
      localStorage.setItem("message", JSON.stringify(this.msgList));
      this.$store.dispatch("messageUnread");
    },
  },
};
</script>

<style lang="scss" scoped>
#x-message {
  position: fixed;
  right: 0;
  bottom: 0;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -15px;
  //   width: 100px;
  //   height: 100px;
  //   background-color: red;
  z-index: 2000;
  .message-item {
    transform: translateX(calc(100% + 10px));
    background-color: white;
    margin: 10px;
    width: 330px;
    height: 234px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    transition: transform 0.3s ease-in-out;
    animation: show 0.3s ease-in-out;
    animation-fill-mode: forwards;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 6px 30px 5px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;

    &.hidden {
      animation: hidden 0.3s ease-in-out;
      animation-fill-mode: forwards;
    }

    .item-header {
      font-size: 14px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .header-icon {
        width: 24px;
      }

      .header-title {
        flex: 1;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
      }

      .item-msg-num {
        // color: ;
        font-size: 12rpx;
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .item-content {
      padding: 10px;
      font-size: 14px;
      line-height: 19px;
      padding: 10px 20px;
      overflow: auto;
      flex: 1;
    }

    .item-footer {
      padding: 10px 20px;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }
}

@keyframes show {
  from {
    transform: translateX(calc(100% + 10px));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes hidden {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100% + 10px));
  }
}
</style>
