<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu
      :default-active="activeMenu"
      background-color="#FFFFFF"
      text-color="rgba(0,0,0,0.6)"
      :unique-opened="false"
      active-text-color="#0052d9"
      :collapse-transition="false"
      mode="vertical"
    >
      <Sidebar
        v-for="(item, index) in routeList"
        :key="item.name"
        :item="item"
      />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "./Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["route"]),
    routeList() {
      let arr = [];
      arr = this.route.filter((item) => {
        return item.hidden !== true;
      });
      //   console.log(arr);
      return arr;
      //   return this.route.filter((item) => {
      //     return item.hidden !== true;
      //   });
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
};
</script>
<style>
.scrollbar-wrapper {
  min-width: 230px;
  padding: 16px 8px;
  box-sizing: border-box;
  /* margin: 16px 0; */
}
</style>
<style lang="scss" scoped>
::v-deep .el-menu {
  border: none;
}

::v-deep .el-submenu__title,
::v-deep .el-menu-item {
  height: 36px;
  background-color: red;
  line-height: 36px !important;
  display: flex;
  align-items: center;
  border-radius: 3px;

  &:hover {
    background-color: #f3f3f3 !important;
  }
}

::v-deep .el-menu-item.is-active {
  background-color: #f2f3ff !important;
}
::v-deep .el-submenu.is-active {
  .el-submenu__title {
    color: black !important;
  }
}
</style>
