import { login, logout, messageUnread } from "@/api/user";
import { userList } from "@/api/rights/user";
// import {
//   getToken,
//   setToken,
//   removeToken,
//   setRoute,
//   removeRoute,
//   setPermission,
//   removePermission,
// } from "@/utils/auth";
import { resetRouter } from "@/router";
// import { getMunuList } from "@/utils/tools";
// import { LocalhostUtils } from "@/utils/localhostUtils";

// const getUserInfo = () => {
//   let userInfo = localStorage.getItem("userInfo");
//   console.log("userInfo", userInfo);
//   if (userInfo) {
//     return JSON.parse(userInfo);
//   } else {
//     return {};
//   }
// };

const getDefaultState = () => {
  return {
    token: localStorage.getItem("token"),
    userInfo: {},
    msgUnReadNum: 0,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminId");
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    localStorage.setItem("token", token);
  },
  SET_USER_INFO: (state, value) => {
    state.userInfo = value;
    localStorage.setItem("userInfo", JSON.stringify(value));
  },
  // SET_AVATAR: (state, avatar) => {
  //   state.avatar = avatar;
  // },
  SET_MSG_UNREAD_NUM: (state, num) => {
    state.msgUnReadNum = num;
  },
};

const actions = {
  // user login
  login({ commit, dispatch }, userInfo) {
    const { username, password, captcha } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password, captcha })
        .then((response) => {
          const { data } = response;
          // localStorage.removeItem("message");
          commit("SET_TOKEN", data.access_token);
          localStorage.setItem("adminId", data.admin_id);
          // dispatch("getUserInfo", data.admin_id).then((info) => {
          //   resolve(info);
          // });
          // setToken(data.access_token);
          // let routeList = data.admin_menu.filter((item) => item.type != "F");
          // let permission = routeList.map((item) => item.perms);
          // setPermission(permission);
          // setRoute(routeList);
          // commit("main/SET_ROUTE_LIST", routeList, { root: true });
          // commit("main/SET_PERMISSION", permission, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 未读消息数量
  messageUnread({ commit, state }, msg) {
    return new Promise((resolve, reject) => {
      messageUnread()
        .then((res) => {
          commit("SET_MSG_UNREAD_NUM", res.data);
          let list = localStorage.getItem("message");
          if (list && list.length > 0) {
            // let arr = JSON.parse(list);
            // commit("SET_MSG_UNREAD_NUM", arr.length);
            // let index = arr.findIndex((item) => item.id == msg.id);
            // if (index > -1) {
            //   arr.splice(index, 1);
            //   localStorage.setItem("message", JSON.stringify(arr));
            // }
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getUserInfo({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      let id = localStorage.getItem("adminId");
      userList({ id, noauth: true })
        .then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            commit("SET_USER_INFO", data[0]);
            resolve(data[0]);
          } else {
            // dispatch("logout", null, { root: true });
            resetRouter();
            commit("RESET_STATE");
            commit("RESET_STATE_MAIN");
            router.push({ path: "/login" });
            reject("用户不存在");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          // removeToken(); // must remove  token  first
          resetRouter();
          // removeRoute();
          // removePermission();
          commit("RESET_STATE");
          commit("RESET_STATE_MAIN");
          // commit("main/SET_ROUTE_STATUS", false, { root: true });
          // commit("main/CLEAR_HISTORY", [], { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  tokenPast({ commit }) {
    // removeToken(); // must remove  token  first
    resetRouter();
    // removeRoute();
    // removePermission();
    commit("RESET_STATE"); // 重置 user state
    commit("RESET_STATE_MAIN"); // 重置 main state
    // commit("main/SET_ROUTE_STATUS", false, { root: true });
    // commit("main/CLEAR_HISTORY", [], { root: true });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      removeRoute();
      removePermission();
      commit("RESET_STATE");
      commit("main/SET_ROUTE_STATUS", false, { root: true });
      commit("main/CLEAR_HISTORY", [], { root: true });
      resolve();
    });
  },
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
};
