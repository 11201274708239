<template>
  <img :src="src" class="x-icon-img" alt="" :style="style" />
</template>

<script>
import { getPath } from "./index.js";
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    size: {
      type: Number || String,
      default: 16,
    },
  },
  computed: {
    src() {
      return getPath(this.name);
    },
    style() {
      return {
        width:
          typeof this.size === "number" ? `${this.size}px` : `${this.size}`,
      };
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.x-icon-img {
  vertical-align: middle;
}
</style>
