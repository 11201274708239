<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
export default {
  data() {
    return {
      version: "0.1.3",
    };
  },
  mounted() {
    let version = localStorage.getItem("version");
    if (!version) {
      localStorage.setItem("version", this.version);
    } else if (version && version !== this.version) {
      MessageBox.confirm("版本已更新，请重新登录", "版本更新", {
        confirmButtonText: "重新登录",
        showCancelButton: false,
        type: "warning",
      }).then(() => {
        this.$store.dispatch("tokenPast").then(() => {
          this.$router.push({ path: "/login" });
        });
        localStorage.clear();
        localStorage.setItem("version", this.version);
      });
    }
  },
};
</script>

<style></style>
