const imgFile = require.context("./icons", true, /\.png$/);
const imgIcons = [];
imgFile.keys().forEach((it) => {
  var match = it.match(/\.\/([^.]+)\.png/);
  if (match) {
    var name = match[1];
    imgIcons.push({
      key: name,
    });
  }
});

const getPath = (key) => {
  return require(`./icons/${key}.png`);
};

export { imgIcons, getPath };
export { default as XIconSelect } from "./index.vue";
export { default as XIcon } from "./XIcon.vue";
