<template>
  <div class="table-layout">
    <div class="table-tools">
      <div class="tools-list" v-if="element.includes('tools')">
        <div v-for="(item, index) in toolsConfig" :key="index">
          <div class="tools-item pointer button-active select-none" v-if="item.type != 'line'"
            v-permission="{ permission: item.permission }" @click="handleToolClick(item)">
            <el-button :type="getButtonType(item.type)" size="mini">
              {{ item.name }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="table-setting-container" v-if="options.length">
        <el-popover placement="bottom" popper-class="table-list-popover" width="180" trigger="click">
          <div class="table-list">
            <div class="table-list-top">
              <div>设置</div>
              <el-button type="text" @click="resetOpt">重置</el-button>
            </div>
            <div style="max-height: 320px; overflow: auto">
              <draggable v-model="options" draggable=".draggable" handle=".mover">
                <transition-group>
                  <div class="table-list-item" v-for="item in options" :key="item.index"
                    :class="[!item.fixed && 'draggable']">
                    <div class="column-handle" :class="[!item.fixed && 'mover']">
                      <i class="el-icon-more"></i>
                      <i class="el-icon-more"></i>
                    </div>
                    <el-checkbox @change="checkboxChange($event, item)" v-model="item.check">
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
          <el-tooltip slot="reference" class="item" effect="dark" content="设置" placement="top">
            <div class="control-btn">
              <i class="el-icon-setting control-btn-icon"></i>
            </div>
          </el-tooltip>
        </el-popover>
        <slot name="custom"></slot>
      </div>
    </div>
    <div class="table-layout-container">
      <div class="table-search" v-if="element.includes('search')">
        <slot name="search" />
      </div>
      <div class="table-container" v-if="element.includes('container')">
        <slot />
      </div>
      <div class="table-footer" v-if="element.includes('footer')">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { options } from "@/views/temperature/project/config";
import draggable from "vuedraggable";
import { getList } from "@/api/system/menu"
import { mapGetters } from "vuex";
import { exportLog } from "@/api/system/index"

export default {
  name: "TableLayout",
  components: { draggable },
  props: {
    toolsConfig: {
      type: Array,
      default: () => [],
    },
    element: {
      type: Array,
      default: () => ["footer", "container", "search", "tools"],
    },
    tableConfig: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    containerStyle() {
      let isFooter = this.element.includes("footer");
      let isHeader = this.element.includes("search");

      let style = {
        borderRadius: "6px",
      };

      isFooter &&
        Object.assign(style, {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        });
      isHeader &&
        Object.assign(style, {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        });

      return style;
    },
    options: {
      get() {
        /* 1. 深拷贝 tableConfig */
        let list = JSON.parse(JSON.stringify(this.tableConfig));
        /* 2. 如果默认配置为空，初始化 */
        if (!this.defaultOptions.length) {
          list = this.tableConfig.map((item, index) => {
            item.check = true;
            item.index = index;
            return item;
          });
          /* 3. 保存默认配置 */
          this.defaultOptions = JSON.parse(JSON.stringify(list));
          /* 4. 检查是否有配置缓存 */
          const optionsStorage = localStorage.getItem(this.$route.path);
          /* 5. 如果有缓存，优先使用用户缓存（该方法会触发 set） */
          this.options = optionsStorage ? JSON.parse(optionsStorage) : list;
        }
        return list;
      },
      set(val) {
        localStorage.setItem(this.$route.path, JSON.stringify(val));
        this.$emit("update:tableConfig", val);
      },
    },
  },
  data() {
    return {
      msg: "home",
      category: [1],
      pageSize: [10, 20, 30, 40, 50],
      defaultOptions: [],
    };
  },
  methods: {
    getButtonType(type) {
      switch (type) {
        case "add":
        case "reply":
        case "upload":
        case "save":
        case "edit":
          return "primary";
        case "export":
        case "import":
        case "download":
          return "info";
        case "delete":
          return "danger";
        default:
          return "";
      }
    },
    handleToolClick(item) {
      this.$emit("handleToolClick", item);
      if (item.type == 'export') {
        this.handleExportLog(item);
      }
    },
    resetOpt() {
      this.options = JSON.parse(JSON.stringify(this.defaultOptions));
    },
    checkboxChange(e, item) {
      let newOptions = JSON.parse(JSON.stringify(this.options));
      this.options = newOptions;
    },
    /** 记录导出操作 */
    async handleExportLog(item) {
      /** 1. 根据权限查询当前按钮模块名称 */
      let buttonRes = await getList({
        perms: item.permission,
        noauth: true
      })
      if (buttonRes.code != 0 || buttonRes.data.length == 0) return
      /** 查询父级菜单信息 */
      let buttonInfo = buttonRes.data[0] || {}
      let parentMenuRes = await getList({
        noauth: true,
        id: buttonInfo.parent_id
      })
      if (parentMenuRes.code != 0 || parentMenuRes.data.length == 0) return
      let parentMenuInfo = parentMenuRes.data[0] || {}
      /** 记录日志 */
      const data = {
        title: parentMenuInfo.name + '导出',
        action: item.permission.replace(/:export/g, ''),
        login_name: this.userInfo.username,
        admin_id: this.userInfo.id
      }
      exportLog(data)
    }
  },
};
</script>

<style lang="scss" scoped>
.table-layout {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  .table-tools {
    height: 64px;
    background-color: white;
    border-radius: 6px;
    position: relative;
    padding: 0px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
    flex: 0 0 auto;

    .tools-list {
      display: flex;
      flex: 1;
      gap: 8px;
      align-items: center;
    }

    .category-line {
      content: "";
      display: block;
      width: 1px;
      background-color: #b2b2b2;
      height: 100%;
      height: 37px;
      // border-right: 1px solid #b2b2b2;
      // margin-right: -8px;
      // padding-right: 8px;
    }

    .tools-item {
      min-width: 42px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
      }

      .tools-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        object-fit: cover;
        margin-top: 5px;
        margin-bottom: 2px;
      }

      .tools-title {
        line-height: 20px;
        font-size: 12px;
      }
    }

    // &::before {
    //   position: absolute;
    //   inset: 0;
    //   content: "";
    //   background: rgba(237, 237, 237, 0.45);
    //   z-index: -1;
    // }
  }

  .table-layout-container {
    background-color: white;
    border-radius: 6px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .table-search {
      padding: 0 32px;
    }

    .table-container {
      padding: 0 32px;
      flex: 1;
    }

    .table-footer {
      height: 48px;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      .page-size {
        font-size: 14px;

        .page-size-select {
          width: 72px;
          height: 32px;
          margin: 0 10px;
        }
      }
    }
  }

  //   background: red;
}

.table-list {
  overflow: hidden;

  .table-list-top {
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    font-size: 15px;
  }

  .table-list-item {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    //
    -webkit-touch-callout: none;
    /*系统默认菜单被禁用*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -khtml-user-select: none;
    /*早期浏览器*/
    -moz-user-select: none;
    /*火狐*/
    -ms-user-select: none;
    /*IE10*/
    user-select: none;
    // cursor: no-drop;

    .column-handle {
      opacity: 0.8;
      cursor: no-drop;
      display: flex;
      flex-direction: column;
      transform: rotateZ(90deg);

      i {
        height: 5px;
        font-size: 10px;
      }

      &.mover {
        cursor: move;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}
</style>
