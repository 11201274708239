import request from "@/utils/request";

export function userList(params) {
  return request({
    url: "/admin/admin/index",
    method: "get",
    params,
  });
}

// 添加用户
export function addUser(data) {
  return request({
    url: "/admin/admin/add",
    method: "post",
    data,
  });
}

// 修改用户
export function editUser(data) {
  return request({
    url: "/admin/admin/edit",
    method: "put",
    data,
  });
}

// 删除用户
export function delUser(id) {
  return request({
    url: "/admin/admin/delete",
    method: "delete",
    params: { id },
  });
}

// 修改用户状态
export function editUserStatus(id) {
  return request({
    url: "/admin/admin/edit_status",
    method: "put",
    data: { id },
  });
}
