import request from "@/utils/request";

export function roleList(params) {
  return request({
    url: "/admin/role/index",
    method: "get",
    params,
  });
}

// 添加角色
export function roleAdd(data) {
  return request({
    url: "/admin/role/add",
    method: "post",
    data,
  });
}

// 修改角色
export function roleEdit(data) {
  return request({
    url: "/admin/role/edit",
    method: "put",
    data,
  });
}

// 删除角色
export function roleDel(id) {
  return request({
    url: "/admin/role/delete",
    method: "delete",
    params: { id },
  });
}

// 角色菜单权限列表
export function roleMenuList(params) {
  return request({
    url: "/admin/role_menu/index",
    method: "get",
    params,
  });
}

// 编辑角色菜单权限
export function roleMenuEdit(data) {
  return request({
    url: "/admin/role_menu/edit",
    method: "put",
    data,
  });
}

// 一级权限目录
export function roleMaterialList(params) {
  return request({
    url: "/admin/role_material/data_scope_list",
    method: "get",
    params,
  });
}

// 权限列表
export function roleMaterialIndex(params) {
  return request({
    url: "/admin/role_material/index",
    method: "get",
    params,
  });
}

//编辑权限列表
export function roleMaterialEdit(data) {
  return request({
    url: "/admin/role_material/edit",
    method: "put",
    data,
  });
}

//修改角色状态
export function roleStatus(id) {
  return request({
    url: "/admin/role/edit_status",
    method: "put",
    data: { id },
  });
}
