import request from "@/utils/request";

// 城市列表
export function areasList(params) {
  return request({
    url: "/admin/areas/index",
    method: "get",
    params: { ...params, noauth: true },
  });
}

// 上传文件
export function uploadFile(data, UploadProgress) {
  return request({
    url: "/admin/file/add",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (UploadProgress && typeof UploadProgress === "function") {
        // 进度条
        UploadProgress(percentCompleted);
      }
    },
  });
}

/** 记录导出日志 */
export function exportLog(params) {
  return request({
    url: "/admin/ulog/add",
    method: "get",
    params: { ...params, noauth: true },
  });
}
