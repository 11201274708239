<template>
  <div class="table-box">
    <!-- <div class="table-header" v-if="showHeader">
      <div class="tip-container">
        <img src="../../assets/icons/tip.png" class="tip-icon" />
        <div class="tip-text" v-if="!checkList.length">
          <span>未选中任何数据</span>
        </div>
        <div class="tip-text check-tips" v-else>
          <div class="check-text">已选中{{ checkList.length }}条数据</div>
          <div class="check-clear" @click="clearSelection">清空</div>
        </div>
      </div>
      <slot name="table-menu"></slot>
      <div class="table-setting">
        <el-popover
          placement="bottom"
          popper-class="table-list-popover"
          width="180"
          trigger="click"
        >
          <div class="table-list">
            <div class="table-list-top">
              <div>设置</div>
              <el-button type="text" @click="resetOpt">重置</el-button>
            </div>
            <div style="max-height: 320px; overflow: auto">
              <draggable
                v-model="options"
                draggable=".draggable"
                handle=".mover"
                @end="dragEnd"
              >
                <transition-group>
                  <div
                    class="table-list-item"
                    v-for="item in options"
                    :key="item.index"
                    :class="[!item.fixed && 'draggable']"
                  >
                    <div
                      class="column-handle"
                      :class="[!item.fixed && 'mover']"
                    >
                      <i class="el-icon-more"></i>
                      <i class="el-icon-more"></i>
                    </div>
                    <el-checkbox @change="dragEnd" v-model="item.check">
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
          <el-tooltip
            slot="reference"
            class="item"
            effect="dark"
            content="设置"
            placement="top"
          >
            <div class="control-btn">
              <i class="el-icon-setting control-btn-icon"></i>
            </div>
          </el-tooltip>
        </el-popover>
      </div>
    </div> -->
    <el-table
      :data="tableData"
      ref="ListTableRef"
      height="0"
      size="mini"
      v-loading="loading"
      :show-summary="isShowSummary"
      :summary-method="getSummaries"
      @selection-change="selectionChange"
      :header-cell-style="headerCellStyle"
      row-key="id"
      :border="border"
      :expand-row-keys="[100]"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      @expand-change="expandChange"
      @header-dragend="headerDragend"
      @sort-change="sortChange"
    >
      <template slot="empty">
        <el-empty description="暂无数据" :image-size="100"></el-empty>
      </template>
      <el-table-column
        :selectable="selectable"
        type="selection"
        width="55"
        fixed="left"
        align="center"
        v-if="type.split(',').includes('selection')"
      ></el-table-column>
      <el-table-column
        v-for="(table, index) in optionsCom"
        :key="index"
        :prop="table.prop"
        :label="table.label"
        :width="table.width || ''"
        :align="table.align || 'center'"
        :fixed="table.fixed || null"
        :sortable="table.sortable || false"
        :show-overflow-tooltip="table.showOverflowTooltip || false"
      >
        <!-- 嵌套列 数据一般只做展示、其他操作根据插槽实现-->
        <template v-if="table.type === 'nestColumn'">
          <el-table-column
            v-for="(nest, columnIndex) in table.children"
            :prop="nest.prop"
            :key="columnIndex"
            :label="nest.label"
            :width="nest.width || ''"
            :align="nest.align || 'center'"
          >
            <template slot-scope="scope">
              <slot
                :name="`custom-${nest.prop}`"
                :scope="scope"
                v-if="nest.type === 'custom'"
              ></slot>
              <span v-else>
                {{ getValue(scope.row, nest.prop) }}
              </span>
            </template>
          </el-table-column>
        </template>
        <template slot-scope="scope">
          <span v-if="table.type === 'index'">
            {{ scope.$index + 1 }}
          </span>
          <!-- 操作菜单 -->
          <slot
            name="menu"
            :scope="scope"
            v-else-if="table.type == 'menu'"
          ></slot>
          <!-- 需要单独设置样式 插槽名 custom-自定义字段 -->
          <slot
            :name="`custom-${table.prop}`"
            :scope="scope"
            v-else-if="table.type === 'custom'"
          ></slot>
          <!-- 输入框 -->
          <div
            v-else-if="table.type == 'input' || scope.row.formType == 'input'"
          >
            <el-input v-model="scope.row[table.prop]"></el-input>
          </div>
          <!-- 带点击功能 -->
          <div
            v-else-if="table.type == 'click'"
            class="on-click-style"
            @click="onClick(scope.row, scope.row[table.prop])"
          >
            {{ scope.row[table.prop] }}
          </div>
          <!-- 图片 -->
          <div v-else-if="table.type == 'img'">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row[table.prop]"
              :preview-src-list="[scope.row[table.prop]]"
            ></el-image>
          </div>
          <!-- 图片组 -->
          <div v-else-if="table.type == 'imgList'">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row[table.prop][0]"
              :preview-src-list="[scope.row[table.prop]]"
            ></el-image>
          </div>
          <!-- 下载 -->
          <div v-else-if="table.type == 'download'">
            <el-link type="primary" :href="scope.row[table.prop] || 'localhost'"
              >下载</el-link
            >
          </div>
          <!-- 其他  -->
          <span v-else>
            <!-- 使 prop支持 xxx.xxxx.xxxx.... -->
            {{ getValue(scope.row, table.prop) || "-" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { debounce } from "@/utils/debounce";
import draggable from "vuedraggable";
export default {
  name: "table-item",
  components: { draggable },
  /*
    tableConfig:{
      label: "字典标识",
      prop: "code",
      width: 170,
      type: "click",
              //            img -- 图片
              //          click -- 可点击
              //        imgList -- 多个图片
              //       download -- 下载
              //         custom -- 自定义样式
      }
  */
  props: {
    tableConfig: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    headerHeight: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
    /** 是否显示合计
     * true 显示
     * string  显示 ，并且设置为首列的文字
     *
     * 默认开启，但是 tableConfig 需要设置 isSum:true
     */
    showSummary: {
      type: String | Boolean,
      default: "合计",
    },
  },
  watch: {
    tableConfig: {
      //每当表格配置文件改变时，重新渲染表格
      handler(newValue) {
        // console.log("newValue", newValue);
        // this.options = newValue;

        // const optionsStorage = localStorage.getItem(this.$route.path);
        // if(caches && optionsStorage){
        //   this.options = JSON.parse(optionsStorage);
        // }else{
        this.options = newValue;
        // }
        // this.options = [];
        // let options = JSON.parse(JSON.stringify(newValue));
        // // 1. 设置默认选中和序号，通过勾选需要展示的列，动态调整表格字段
        // this.options = newValue;
        // this.options = (newValue || []).map((item, index) => {
        //   // console.log('item',item)
        //   if (item.check == undefined) {
        //     item.check = true;
        //     item.index = index;
        //   }
        //   return item;
        // });
        // if (this.showHeader == false) {
        //   this.options = options;
        //   return;
        // }

        // 2. 保存默认配置，用于重置
        // this.defaultOptions = JSON.parse(JSON.stringify(options));

        // 3. 检查是否有配置缓存
        // const optionsStorage = localStorage.getItem(this.$route.path);

        // 4. 如果有缓存，优先使用用户缓存
        // if (optionsStorage && optionsStorage.length > 0) {
        //   this.options = JSON.parse(optionsStorage);
        // } else {
        //   this.options = options;
        // }
        // this.options = options;

        // 5. 重新渲染
        this.$nextTick(() => {
          this.$refs.ListTableRef.doLayout();
        });
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(newValue) {
        this.tableData = newValue || [];
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(newValue) {
        // console.log(newName, "表格内容改变了");
        this.$emit("input", newValue);
      },
      // immediate: true,
      deep: true,
    },
  },
  computed: {
    optionsCom() {
      if (this.showHeader == true) {
        localStorage.setItem(this.$route.path, JSON.stringify(this.options));
      }
      return this.options.filter(
        (item) => item.check || item.check === undefined
      );
    },
    isShowSummary() {
      if (this.options && this.options.length > 0) {
        let keys = this.getSumKey(this.options);
        return keys.length > 0 && Boolean(this.showSummary);
      }

      return Boolean(this.showSummary);
    },
  },
  data() {
    return {
      screenHeight: document.documentElement.clientHeight, //视图高度
      tableData: [], //传递过来的表格数据
      checkList: [], //选中表格数据
      options: [],
      // defaultOptions: [],
      headerCellStyle: {
        height: "36px",
        fontSize: "14px",
        fontWeight: "400",
        color: "#7F7F7F",
        background: "#ffffff",
        // background:
        //   "linear-gradient(180deg, rgba(237, 237, 237, 0.6) 0%, #e5e5e5 50%, rgba(246, 246, 246, 0.5) 100%)",
      },
      expandRowKeys: [],
      isExpand: false,
      isSum: true,
    };
  },
  created() {
    // 使用防抖函数节约性能
    window.addEventListener("resize", debounce(this.onSize, 500));
  },
  methods: {
    // 清除选中
    clearSelection() {
      this.$refs.ListTableRef.clearSelection();
    },
    //判断当前行是否可以选中
    selectable(row) {
      return !row.disabled;
    },
    //监听视图变化并动态调整高度
    onSize() {
      // this.screenHeight = document.documentElement.clientHeight;
      this.$nextTick(() => {
        this.$refs.ListTableRef && this.$refs.ListTableRef.doLayout();
      });
    },
    //选中项改变事件
    selectionChange(val) {
      this.checkList = val;
      this.$emit("selection", val);
    },
    // 宽度改变时
    headerDragend(newWidth, oldWidth, column, event) {
      if (!this.showHeader) return;
      // console.log(newWidth, oldWidth, column, event);
      const newOptions = this.options.map((item) => {
        if (item.prop == column.property) {
          item.width = newWidth;
        }
        return item;
      });
      localStorage.setItem(this.$route.path, JSON.stringify(newOptions));
    },
    // 全选 通过$refs 调用此方法，选中值 会通过   this.$emit("selection", val); 返回
    checkAll() {
      this.$refs.ListTableRef.toggleAllSelection();
    },
    //前往指定页面
    goPath(url, row) {
      console.log("前往指定页面：", url);
      this.$emit("gopath", { url, row });
    },
    //点击单元格事件，前置条件 - 设置了config[index].type为可点击
    onClick(row, val) {
      console.log("点击单元格", row, val);
      // 第一个参数表示当前点击的这一行数据，第二个参数表示点击的部位
      this.$emit("onClick", row, val);
    },
    //获取单元格数据
    getValue(val, key) {
      return key
        .split(".")
        .reduce(
          (obj, k) =>
            obj && obj[k] !== undefined && obj[k] !== null ? obj[k] : "",
          val
        );
    },
    //表格行样式 - 暂时停用，有需要自行设置
    rowStyle({ row, rowIndex }) {
      // console.log(row, rowIndex);
      return rowIndex % 2 == 0 ? "background:#F9F9FE" : "";
    },
    //
    expandChange(row, isExpand) {
      // console.log(row, isExpand);
      this.isExpand = isExpand;
    },
    // 是否展开table(展开与折叠切换)
    handleExpand() {
      this.isExpand = !this.isExpand;
      this.$nextTick(() => {
        this.forArr(this.tableData, this.isExpand);
      });
    },
    // 展开/折叠，递归方法
    forArr(arr, isExpand) {
      arr.forEach((i) => {
        // toggleRowExpansion(i, isExpand)用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
        this.$refs.ListTableRef.toggleRowExpansion(i, isExpand);
        if (i.children) {
          this.forArr(i.children, isExpand);
        }
      });
    },

    //重置表格配置
    resetOpt() {
      this.options = JSON.parse(JSON.stringify(this.defaultOptions));
      this.$nextTick(() => {
        this.$refs.ListTableRef.doLayout();
      });
    },

    // 拖动结束，重新渲染表格
    dragEnd() {
      this.$nextTick(() => {
        this.$refs.ListTableRef.doLayout();
      });
    },

    /** 递归获取所有需要合计的 key */
    getSumKey(list = []) {
      const sumKey = [];

      function recursion(list) {
        list.forEach((item) => {
          item.isSum && sumKey.push(item.prop);
          if (item.children && item.children.length) {
            recursion(item.children);
          }
        });
      }

      recursion(list);
      return sumKey;
    },

    getNewKey(list = []) {
      const newKey = [];

      function recursion(list) {
        list.forEach((item) => {
          item.isNew && newKey.push(item.prop);
          if (item.children && item.children.length) {
            recursion(item.children);
          }
        });
      }

      recursion(list);
      return newKey;
    },

    /** 表单合计 */
    getSummaries(param) {
      const { columns, data } = param;
      const sumKey = this.getSumKey(this.options);

      const newKey = this.getNewKey(this.options);

      let title =
        typeof this.showSummary === "string" ? this.showSummary : "合计";
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = title || "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // console.log("values", values);
        if (
          !values.every((value) => isNaN(value)) &&
          sumKey.includes(column.property)
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev.add(curr);
            } else {
              return prev;
            }
          }, 0);
          /** 此处可以根据需求，自定义合计后缀单位*/
          // sums[index] += "";
        } else if (newKey.includes(column.property)) {
          /** 合计取第一个 */
          sums[index] = values[0];
        } else {
          sums[index] = "-";
        }
      });
      this.$nextTick(() => {
        this.$refs.ListTableRef && this.$refs.ListTableRef.doLayout();
      });
      return sums;
    },

    // 排序方法
    sortChange(e) {
      this.$emit("sortChange", e);
    },
  },
  destroyed() {
    // window.removeEventListener("resize", this.onSize);
  },
};
</script>
<style lang="scss" scoped>
.table-box {
  flex: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table_btn_box {
  margin: 15px 0;
  margin-bottom: 0;

  .table-footer-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  .table-footer-center {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .table-footer-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

// .table-layout {
//   flex: 1;
//   padding: 10px;
// }

.el-table {
  // margin: 15px;
  text-align: center;
  border-radius: 5px;
}

.table-list {
  overflow: hidden;

  .table-list-top {
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    font-size: 15px;
  }

  .table-list-item {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    //
    -webkit-touch-callout: none;
    /*系统默认菜单被禁用*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -khtml-user-select: none;
    /*早期浏览器*/
    -moz-user-select: none;
    /*火狐*/
    -ms-user-select: none;
    /*IE10*/
    user-select: none;
    // cursor: no-drop;

    .column-handle {
      opacity: 0.8;
      cursor: no-drop;
      display: flex;
      flex-direction: column;
      transform: rotateZ(90deg);

      i {
        height: 5px;
        font-size: 10px;
      }

      &.mover {
        cursor: move;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}

// // 按钮组切换
// .el-radio-button__inner {
//   // border-left: 1px solid rgb(167, 171, 181);
//   border: none;
//   color: rgb(167, 171, 181) !important;
// }
// .el-radio-button__orig-radio:checked + .el-radio-button__inner {
//   color: #fff !important;
// }
// .el-radio-button:first-child .el-radio-button__inner {
//   border: none;
// }
// 按钮组切换
.el-radio-button__inner {
  border-left: 1px solid #dcdfe6 !important;
  border-radius: 4px !important;
  color: rgb(167, 171, 181) !important;
}

.el-radio-button:first-child .el-radio-button__inner {
  border: 1px solid #dcdfe6 !important;
  border-radius: 4px;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff !important;
}

.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 4px;
}

.el-radio-button:first-child .el-radio-button__inner {
  border: 4px;
}

.el-radio-button {
  margin: 0 5px;
}

.btn_cut {
  padding: 0 10px 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(245, 246, 250);
}

.dark-side.theme_color_blue
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  border-radius: 4px;
  background: #4880ff;
  box-shadow: 0px 3px 31px 0px rgba(72, 128, 255, 0.28);
}

.on-click-style {
  color: #409eff;
  cursor: pointer;
}

.table-header-class {
  background-color: red;
}

.table-header {
  height: 40px;
  margin-bottom: 12px;
  display: flex;

  .tip-container {
    flex: 1;
    border-radius: 2px;
    border: 1px solid #87b7ef;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0 24px;
    background: #ebf4ff;
    height: 100%;
  }

  .tip-icon {
    width: 20px;
    margin-right: 8px;
  }

  .tip-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
  }

  .check-tips {
    display: flex;
    height: 100%;
    align-items: center;

    .check-text {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .check-text::after {
      content: "";
      display: block;
      width: 1px;
      height: 12px;
      background: rgba(0, 0, 0, 0.1);
      margin: 0 10px;
    }

    .check-clear {
      color: #398ced;
      cursor: pointer;
    }
  }

  .table-setting {
    width: 52px;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .el-table td,
// .el-table th.is-leaf {
//   border: none;
// }
// .el-table {
//   border: 1px solid #ebeef5;
// }

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  height: 0px !important;
}
</style>
<style>
.table-list-popover {
  padding: 0 !important;
}

.control-btn-icon {
  font-size: 20px;
  color: #666666;
  cursor: pointer;
}
</style>
