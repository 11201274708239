<template>
  <div class="x-paging">
    <!-- 分页 -->
    <el-pagination
      background
      class="paging"
      :layout="element"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      :pager-count="pagerCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: {
        offset: 1,
        limit: 10,
        total: 0,
      },
    },
    pageSizes: {
      type: Array,
      default: () => [10, 30, 50, 100, 500, 1000],
    },
    element: {
      type: String,
      default: "total, sizes,prev, pager, next,jumper",
    },
    pagerCount: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    pageNum: {
      get() {
        return this.value.page;
      },
      set(val) {
        this.$emit("input", { ...this.value, page: val });
        this.$emit("change");
      },
    },
    pageSize: {
      get() {
        return this.value.limit;
      },
      set(val) {
        this.$emit("input", { ...this.value, limit: val, page: 1 });
        this.$emit("change");
      },
    },
    total() {
      return Number(this.value.total);
    },
  },
  data() {
    return {};
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.x-paging {
  ::v-deep .el-pagination__jump {
    background-color: #f3f3f3;
    border-radius: 3px;
    padding: 0px 8px;

    .el-pagination__editor.el-input .el-input__inner {
      height: 24px;
    }
  }

  ::v-deep .el-pagination.is-background {
    .el-pager li:not(.disabled).active {
      background-color: #0052d9;

      &:hover {
        color: white;
      }
    }

    .btn-prev,
    .btn-next {
      background-color: transparent;

      &:hover {
        color: #0052d9;
      }
    }

    .el-pager li {
      background-color: transparent;
      border: 1px solid #dcdcdc;
      line-height: 26px;
      border-radius: 3px;
    }

    .el-pager li:not(.disabled):hover {
      color: #0052d9;
    }
  }
}
</style>
