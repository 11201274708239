<template>
  <div class="x-icon-select">
    <el-select
      v-model="iconName"
      style="width: 100%"
      size="mini"
      placeholder="请选择"
      clearable
    >
      <el-option
        v-for="item in iconList"
        :key="item.key"
        :label="item.key"
        :value="item.key"
      >
        <div class="icon-item">
          <XIcon :name="item.key" :size="18" />
          <span>{{ item.key }}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { imgIcons } from "./index.js";
import XIcon from "./XIcon.vue";
export default {
  components: { XIcon },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    iconName: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      iconList: imgIcons,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
