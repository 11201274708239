export const tableConfig = [
  { label: "项目编号", prop: "project_num", fixed: "left" },
  { label: "项目名称", prop: "project_name" },
  { label: "联络人名称", prop: "liaison_name" },
  { label: "联络人电话", prop: "liaison_phone" },
  { label: "安装地址", prop: "project_addr" },
  { label: "客户名称", prop: "customer_name", type: "custom" },
  { label: "创建时间", prop: "created_at" },
  { label: "更新时间", prop: "updated_at" },
  {
    label: "操作",
    prop: "operation",
    type: "menu",
    fixed: "right",
  },
];

export const toolsConfig = [
  { name: "新增", icon: "add", type: "add", permission: "admin:temperature_project:project_add" },
  // { name: "已读", icon: "read", type: "add" },
  // { name: "删除", icon: "delete", type: "delete" },
  // { name: "清空", icon: "clear", type: "clear" },
  { name: "分割线", type: "line" },
  // { name: "导出", icon: "export", type: "export" },
  { name: "刷新", icon: "refresh", type: "refresh", permission: "*" },
  // { name: "保存", icon: "save", type: "save" },
  // { name: "导入", icon: "import", type: "import" },
  // { name: "折叠/展开", icon: "fold", type: "fold" },
  // { name: "编辑", icon: "edit", type: "edit" },
  // { name: "回帖", icon: "reply", type: "reply" },
  // { name: "下载", icon: "download", type: "download" },
];

export const searchConfig = [
  {
    attrs: { typeName: "input", disabled: false, clearable: false },
    formItem: {
      label: "项目名称",
      prop: "project_name",
    },
  },
  {
    attrs: {
      typeName: "select",
      clearable: false,
      options: [],
      props: {
        label: "customer_name",
        value: "customer_name",
      },
    },
    formItem: {
      label: "客户名称",
      prop: "customer_name",
    },
  },
  {
    attrs: { typeName: "input", disabled: false, clearable: false },
    formItem: {
      label: "联络人名称",
      prop: "liaison_name",
    },
  },
  {
    attrs: {
      typeName: "date-picker",
      clearable: false,
      type: "daterange",
      valueFormat: "yyyy-MM-dd",
    },
    formItem: { label: "创建时间", prop: "created_at" },
  },
];

export const options = [
  { label: "项目编号", key: "project_num" },
  { label: "项目名称", key: "project_name" },
  { label: "联络人名称", key: "liaison_name" },
  { label: "联络人电话", key: "liaison_phone" },
  { label: "安装地址", key: "project_addr" },
  { label: "客户名称", key: "customer_name" },
  { label: "设备连接", key: "connection" },
  { label: "系统温度", key: "temperature" },
];
