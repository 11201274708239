export default {
  bind(el, binding, vnode) {
    // 绑定指令时的初始化逻辑
    el.handler = function (event) {
      let inputValue = event.target.value;
      console.log(event.target.value);
      if (!inputValue || isNaN(inputValue) || inputValue < 0) {
        // vnode.context.errorMessage = "请输入一个非负数。";
        event.target.value = "";
      } else {
        event.target.value = Number(inputValue);
      }
    };

    // 监听input事件，调用handler
    el.addEventListener("input", el.handler);
  },
  unbind(el) {
    // 解绑指令时的清理逻辑
    el.removeEventListener("input", el.handler);
  },
};
