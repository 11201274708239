<!--
 * new page
 * @author: Log Horizon
 * @since: 2024-05-24
 * layout-view.vue
-->
<template>
  <div class="layout-view">
    <!-- 原逻辑 -->
    <!-- <div class="route-list">
      <router-link
        :to="item.path"
        v-for="item in currentRoute.children"
        :key="item.path"
      >
        <div class="route-item" :class="item.path == $route.path && 'active'">
          {{ item.meta.title }}
        </div>
      </router-link>
    </div> -->
    <div class="menu_list">
      <el-menu
        :unique-opened="true"
        text-color="rgba(0,0,0,0.6)"
        active-text-color="#0052d9"
        class="route-list"
        :default-active="key"
        @select="menuSelectChange"
      >
        <template v-for="item in currentRoute.children">
          <el-submenu :index="item.path" v-if="item.children.length > 0">
            <template slot="title">
              <span> {{ item.meta.title }}</span>
            </template>
            <el-menu-item
              :index="v2.path"
              v-for="v2 in item.children"
              :key="v2.path"
            >
              {{ v2.meta.title }}</el-menu-item
            >
          </el-submenu>
          <template v-else>
            <el-menu-item :index="item.path">{{
              item.meta.title
            }}</el-menu-item>
          </template>
        </template>
      </el-menu>
    </div>

    <div class="layout-main">
      <router-view :key="key" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "layout-view",
  computed: {
    ...mapGetters(["currentRoute"]),
    key() {
      return this.$route.path;
    },
  },
  methods: {
    menuSelectChange(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style scoped lang="scss">
.layout-view {
  height: 100%;
  display: flex;

  .route-list {
    height: calc(100% - 40px);
    background-color: white;
    border-radius: 6px;
    padding: 12px;
    flex: 0 0 auto;
    // margin: 20px;
    margin-right: 0;

    .route-item {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 36px;
      padding: 0 24px;
      border-radius: 5px;
      cursor: pointer;

      &.active {
        background-color: #f2f3ff;
        color: rgb(0, 82, 217);
      }
    }
  }

  .layout-main {
    flex: 1;
    overflow: hidden;
  }
}

::v-deep .el-submenu .el-menu-item {
  min-width: none !important;
}

.menu_list {
  height: calc(100% - 40px);
  background-color: white;
  border-radius: 6px;

  flex: 0 0 auto;
  margin: 16px;
  margin-right: 0;
  margin-top: 20px;
  width: 225px;

  ::v-deep .el-menu {
    border: none;
    margin: none !important;
  }

  ::v-deep .el-submenu .el-menu-item {
    min-width: none !important;
  }
  ::v-deep .el-submenu__title,
  ::v-deep .el-menu-item {
    height: 40px;

    line-height: 40px !important;
    display: flex;
    align-items: center;
    border-radius: 3px;

    &:hover {
      background-color: #f3f3f3 !important;
    }
  }

  ::v-deep .el-menu-item.is-active {
    background-color: #f2f3ff !important;
  }
  // ::v-deep .el-menu .is-opened .el-submenu__title {
  //   color: rgba(0, 0, 0, 1) !important;
  // }
  ::v-deep .el-menu .is-active .el-submenu__title {
    color: rgba(0, 0, 0, 1) !important;
  }
}
</style>
