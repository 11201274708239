/**
 * 权限自定义指令，两种方式，直接删除按钮 / 禁用按钮
 * @param permission: 权限标识 , * 为不需要权限
 * @param type: remove 删除按钮 / 其他 禁用按钮
 * 示例 v-permission="{permission: 'a', type: 'remove'}"
 */
import store from "@/store";

export default {
  update(el, binding, vnode) {
    let buttonKey = binding.value?.permission;
    let type = binding.value?.type;
    if (buttonKey === "*") {
      el.classList.remove("disabled");
      el.classList.add("button-active");
      // let mask = document.getElementById("tools-mask");
      el._childEle && el._childEle.remove();
      el._childEle = null;

      return;
    }
    let key = checkKey(buttonKey);
    if (type == "remove") {
      if (!key) {
        //没有权限
        el.remove(); //删除按钮
      }
    } else {
      if (!key) {
        // 移除点击样式
        el.classList.remove("button-active");
        el.style.position = "relative";
        // 添加禁用样式
        el.classList.add("disabled");

        // 方案1. 添加 遮罩 禁用拦截点击事件，如需还原，移除遮罩即可
        if (!el._childEle) {
          const mask = document.createElement("div");
          mask.classList.add("mask");
          mask.style.position = "absolute";
          mask.style.inset = "0";
          el.appendChild(mask);
          el._childEle = mask;
          mask.addEventListener("click", (event) => {
            event.stopPropagation();
            // 这里放置你想要执行的代码
          });
        }
        // 添加遮罩拦截点击事件

        // 方案2. 直接拦截点击事件
        // el.addEventListener(
        //   "click",
        //   (event) => {
        //     event && event.stopImmediatePropagation();
        //   },
        //   true
        // );
      }
    }
  },
};

function checkKey(key) {
  let isAdmin = store.getters.userInfo.id == 1;
  if (isAdmin) {
    return true;
  }
  // 获取权限数组
  let permissionData = store.getters.permission;
  //如果传入的元素key不在权限数组里，则不可显示
  let index = permissionData.indexOf(key);
  return index > -1;
}
