import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
// import { getToken } from "@/utils/auth";
import router from "@/router";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: "https://test3.whxzs.net",
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 10000, // request timeout
});

let isTokenExpiredPrompted = false;

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["authorization"] = "Bearer " + store.getters.token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0 && !!res.code) {
      if (res.code == 401) {
        // 避免重复提示
        if (!isTokenExpiredPrompted) {
          isTokenExpiredPrompted = true;
          MessageBox.confirm("登录状态过期，请重新登录！", "确定登出", {
            confirmButtonText: "重新登录",
            showCancelButton: false,
            type: "warning",
          }).then(() => {
            store
              .dispatch("tokenPast")
              .then(() => {
                router.push({ path: "/login" });
                isTokenExpiredPrompted = false;
              })
              .catch(() => {
                isTokenExpiredPrompted = false;
              });
          });
        }
      }
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
