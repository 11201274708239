<template>
  <section class="app-main">
    <div class="route-tags-container">
      <div class="tags-prev flex-center pointer" @click="scrollBtn('prev')">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="route-tags" ref="routeTagsRef">
        <div
          class="tags-item pointer select-none"
          v-for="(item, index) in routeHistory"
          :key="index"
          :class="$route.meta.menu_id == item.meta.menu_id && 'active'"
          @click="changeRoute(item)"
          @contextmenu.prevent.stop="openMenu($event, item, index)"
        >
          <div>{{ item.meta.title }}</div>
          <i class="el-icon-close" @click.stop="closeRoute(index)"></i>
        </div>
      </div>
      <div class="tags-next flex-center pointer" @click="scrollBtn('next')">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="main-container">
      <!-- <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition> -->
      <router-view :key="key" />
    </div>
    <ul
      v-show="showContextMenu"
      id="context-menu-wrapper"
      :style="contextMenuStyle"
      class="select-none"
    >
      <li
        v-for="(item, index) in menuConfig"
        :key="index"
        @contextmenu.prevent.stop=""
        @click.stop="clickMenu(item)"
        :style="{ color: item.color }"
        class="enable select-none"
        :class="{
          disabled: isDisabled(item),
        }"
      >
        {{ item.label }}
      </li>
    </ul>
    <div
      class="menu-shade"
      @contextmenu.prevent.stop="closeMenu"
      @click.stop="closeMenu"
      v-show="showContextMenu"
    ></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AppMain",
  data() {
    return {
      current: 0,
      checkedData: {},
      showContextMenu: false, // 右键菜单是否显示
      contextMenuStyle: {
        left: 0,
        top: 0,
      },
      menuConfig: [
        {
          label: "重新加载",
          icon: "refresh",
          type: "reload",
        },
        {
          label: "关闭左侧",
          icon: "closeLeft",
          type: "closeLeft",
        },
        {
          label: "关闭右侧",
          icon: "closeRight",
          type: "closeRight",
        },
        {
          label: "关闭其他",
          icon: "closeOther",
          type: "closeOther",
        },
        {
          label: "关闭全部",
          icon: "closeAll",
          type: "closeAll",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["routeHistory", "layoutViewRoute"]),
    key() {
      return this.$route.path;
    },
  },
  watch: {
    $route: {
      handler() {
        this.getRouteHistory();
        this.tagScroll();
        this.getParentRoute(this.$route);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    changeRoute(item) {
      this.getParentRoute(item);
      this.$router.push({
        name: item.name,
        params: item.params,
        query: item.query,
      });
    },
    //
    isDisabled(item) {
      return (
        (item.type == "closeLeft" && this.current == 0) ||
        (item.type == "closeRight" &&
          this.current == this.routeHistory.length - 1) ||
        (item.type != "reload" && this.routeHistory.length == 1)
      );
    },
    // 添加路由历史记录
    getRouteHistory(item) {
      const { hash, matched, ...route } = this.$route;
      this.$store.commit("SET_HISTORY", route);
    },
    closeRoute(index) {
      let path = this.routeHistory[index].path;
      if (this.$route.path === path && this.routeHistory.length > 1) {
        this.$store.commit("DEL_HISTORY", index);
        if (index !== 0) {
          this.$router.push(this.routeHistory[index - 1].path);
        } else {
          this.$router.push(this.routeHistory[index].path);
        }
      } else if (this.routeHistory.length === 1) {
        return this.$message.error("至少保留一个标签页！");
      } else if (this.$route.path !== path && this.routeHistory.length > 1) {
        this.$store.commit("DEL_HISTORY", index);
      }
    },
    // 滚动
    scrollBtn(e) {
      let routeTagsRef = this.$refs.routeTagsRef;
      routeTagsRef.scroll({
        left:
          e === "prev"
            ? routeTagsRef.scrollLeft - 100
            : routeTagsRef.scrollLeft + 100,
        behavior: "smooth", // 可以使滚动动画化
      });
    },
    // 标签右键菜单
    openMenu(ctx, item, index) {
      this.current = index;
      this.checkedData = item;
      const { clientX, clientY } = ctx;
      const screenWidth = document.body.clientWidth;
      this.contextMenuStyle.left =
        (clientX + 120 > screenWidth ? clientX - 105 : clientX + 10) + "px";
      this.contextMenuStyle.top = clientY + "px";
      this.showContextMenu = true;
    },
    closeMenu() {
      this.showContextMenu = false;
    },
    clickMenu(item) {
      // 判断是否禁用
      if (this.isDisabled(item)) return;
      switch (item.type) {
        case "reload":
          this.$router.go(0);
          break;
        case "closeLeft":
          this.$store.commit("DEL_LEFT", this.current);
          this.$router.push(this.checkedData.path);
          break;
        case "closeRight":
          this.$store.commit("DEL_RIGHT", this.current);
          this.$router.push(this.checkedData.path);
          break;
        case "closeOther":
          this.getParentRoute(this.checkedData);
          this.$store.commit("DEL_OTHER", this.checkedData);
          this.$router.push(this.checkedData.path);
          break;
        case "closeAll":
          this.showContextMenu = false;
          this.$store.commit("DEL_ALL", this.current);
          this.$router.push(this.routeHistory[this.current].path);

          break;
      }
      this.showContextMenu = false;
    },
    getParentRoute(item) {
      let { path } = item;
      path = path.split("/");
      const parentPath = `/${path[1]}/${path[2]}`;
      let parentRoute = this.layoutViewRoute.find(
        (item) => item.path == parentPath
      );
      if (parentRoute && parentRoute.children.length) {
        this.$store.commit("SET_CURRENT_ROUTE", parentRoute);
      }
    },
    //
    tagScroll() {
      this.$nextTick(() => {
        let routeTagsRef = this.$refs.routeTagsRef;
        let index = this.routeHistory.findIndex(
          (item) => item.path == this.$route.path
        );
        const activeNode = routeTagsRef.childNodes[index];
        const scrollLeft =
          activeNode.offsetLeft -
          (index == this.routeHistory.length - 1 ? 320 : 600);
        routeTagsRef.scroll({
          left: scrollLeft,
          behavior: "smooth", // 可以使滚动动画化
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  height: 100%;
  flex: 1;
  overflow: hidden;
  background: #f3f3f3;

  .menu-shade {
    position: fixed;
    inset: 0;
    z-index: 9900;
  }

  #context-menu-wrapper {
    position: absolute;
    width: 100px;
    z-index: 9999;
    -webkit-box-shadow: 0px 0 2px rgba(0, 21, 41, 0.15);
    box-shadow: 0px 0 2px rgba(0, 21, 41, 0.15);
    background-color: white;
    padding: 0;
    margin: 0;
    // border: 1px solid rgba(0, 0, 0, 0.3);
    // border-radius: 5px;
    list-style: none;

    & > li {
      width: 100%;
      line-height: 30px;
      padding-left: 15px;
      box-sizing: border-box;
      cursor: pointer;
      color: #495057;
      font-size: 14px;

      &.disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.4) !important;
      }
    }

    & > li:first-child::after {
      display: block;
      content: "";
      border-bottom: 1px solid #eee;
      width: 70px;
    }

    & > li:last-child::before {
      display: block;
      content: "";
      border-bottom: 1px solid #eee;
      width: 70px;
    }

    .enable:hover {
      background-color: #f5f5f5;
    }

    .disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.4) !important;
    }
  }

  .route-tags-container {
    display: flex;
    overflow: hidden;
    border-bottom: 1px solid #e7e7e7;
    flex: 1;
    height: 48px;

    .tags-prev,
    .tags-next {
      background: #f3f3f3;
      border: 1px solid #e7e7e7;
      width: 40px;
      border-bottom: none;
    }

    .route-tags {
      display: flex;
      flex: 1;
      height: 48px;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &::-webkit-scrollbar-horizontal {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }

      .tags-item {
        height: 48px;
        flex: 0 0 auto;
        border-right: 1px solid #e7e7e7;
        font-size: 14px;
        // font-weight: 600;
        // position: relative;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: rgba(0, 0, 0, 0.6);

        &.active {
          background: #fff;
          color: #0052d9;
        }

        .el-icon-close {
          margin-top: 2px;
          font-weight: bold;
          font-size: 12px;
        }

        .icon-close {
          color: #666666;
          // margin-left: 5px;
        }

        // &::before {
        //   position: absolute;
        //   inset: 0;
        //   content: "";
        //   background: rgba(237, 237, 237, 0.55);
        //   z-index: -1;
        // }
      }
    }
  }

  .main-container {
    // border: 1px solid rgba(0, 0, 0, 0.3);
    height: calc(100% - 37px);
  }
}

/* .app-main {
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header+.app-main {
  padding-top: 50px;
} */
</style>

<style lang="scss" scope>
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
.route-tags {
  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
</style>
