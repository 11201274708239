<template>
  <div class="navbar select-none">
    <!-- :style="{ backgroundSize: innerWidth + 'px 80px' }" -->
    <!-- <img src="../../assets/images/header.png" alt=""> -->
    <div class="navbar-logo-box">
      <img
        class="logo-img"
        src="../../assets/images/logo-new.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="nav-right">
      <el-select
        v-model="keyword"
        filterable
        size="small"
        remote
        reserve-keyword
        placeholder="请输入关键词"
        :remote-method="remoteMethod"
        @change="selectChange"
      >
        <div slot="prefix" class="el-input-prefix-icon">
          <i class="el-icon-search"></i>
        </div>
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.url"
        >
        </el-option>
      </el-select>
      <div class="nav-menu">
        <!-- div. -->
        <el-tooltip
          v-for="item in menuList"
          :key="item.key"
          effect="dark"
          :content="item.name"
          placement="bottom"
        >
          <div class="menu-item" @click="menuClick(item)">
            <img :src="require(`../../assets/icons/${item.icon}.png`)" alt="" />
          </div>
        </el-tooltip>
      </div>

      <el-dropdown trigger="click">
        <div class="user-wrapper">
          <div class="user-name">{{ userInfo.realname }}</div>
          <i class="el-icon-arrow-down"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/personal/home">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <div class="user-name">欢迎您，{{ userInfo.realname }}</div>
      <div class="right-menu-bottom">
        <span class="user-msg pointer red" v-if="msgUnReadNum" @click="goMsg"
          >{{ msgUnReadNum || 0 }}条消息</span
        >
        <span class="pointer" @click="goPersonal">个人中心</span>
        <span class="pointer" @click="dialogVisible = true">修改密码</span>
        <span class="pointer" @click="logout">退出</span>
      </div> -->
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
      :append-to-body="true"
      custom-class="x-dialog"
    >
      <div style="margin: 10px 20px">
        <el-form
          :model="form"
          :rules="rules"
          label-position="right"
          size="mini"
          ref="formRef"
          label-width="80px"
        >
          <el-form-item label="旧密码" prop="old_password">
            <el-input
              v-model="form.old_password"
              size="mini"
              type="password"
              placeholder="请输入旧密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="new_password_1">
            <el-input
              v-model="form.new_password_1"
              size="mini"
              type="password"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="new_password_2">
            <el-input
              v-model="form.new_password_2"
              size="mini"
              type="password"
              placeholder="请再次输入新密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          style="margin-right: 10px"
          class="search-button"
          @click="submitClick"
        >
          确定
        </button>
        <button class="reset-button" @click="cancel">取消</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editPassword } from "@/api/user";
import { mapGetters } from "vuex";

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.new_password_1) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {},
      keyword: "",
      options: [],
      menuList: [
        {
          key: "password",
          name: "修改密码",
          icon: "password",
        },
        {
          key: "message",
          name: "消息",
          icon: "message",
        },
      ],
      dialogVisible: false,
      rules: {
        old_password: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        new_password_1: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        new_password_2: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      innerWidth: 0,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "msgUnReadNum", "routeList"]),
  },
  mounted() {
    this.innerWidth = screen.width;
    console.log(this.innerWidth);
    // 未读消息方案  1. 每5秒请求一次
    // setInterval(() => {
    //   this.$store.dispatch("messageUnread");
    // }, 5000);
    // 2. 初始查询一次，在所有跟消息相关的页面都会触发查询
    this.$store.dispatch("messageUnread");
  },
  methods: {
    /* 搜索 */
    async remoteMethod(query) {
      if (query !== "") {
        let res = this.routeList.filter((item) => {
          return (
            item.name.indexOf(query) > -1 &&
            item.status === 1 &&
            item.parent_id != 0
          );
        });
        this.options = [...res];
      } else {
        this.options = [];
      }
    },
    /* 点击菜单 */
    selectChange(url) {
      if (!url) return this.$message("该功能正在开发中", "warning");
      this.$router.push(url);
      this.keyword = "";
      this.options = [];
    },
    menuClick(item) {
      switch (item.key) {
        case "password":
          this.dialogVisible = true;
          break;
        case "message":
          this.$router.push("/personal/message");
          break;
      }
    },

    // 获取未读信息
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    // goUserInfo() {
    //   // this.$router.push({});
    // },
    //关闭确认
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancel();
          done();
        })
        .catch((_) => {});
    },
    // goPersonal() {
    //   this.$router.push("/personal/home");
    // },
    // goMsg() {
    //   this.$router.push("/personal/message");
    // },
    async submitClick() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          try {
            await editPassword({ ...this.form, id: this.userInfo.id });
            this.$message.success("操作成功");
            this.cancel();
            this.$emit("refresh");
          } catch (error) {
            console.log(error);
            this.$message.error(error.msg);
          }
        }
      });
    },
    cancel() {
      this.form = {};
      this.dialogVisible = false;
      this.isEdit = false;
      if (this.$refs.formRef) {
        this.$refs.formRef.resetFields();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: relative;
  height: 56px;
  padding: 0 48px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;

  .navbar-logo-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 230px;
    width: 230px;

    .logo-img {
      width: 147px;
      // height: 47px;
      object-fit: cover;
      margin-right: 5px;
    }
  }

  .nav-right {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    .el-input-prefix-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 26px;
    }

    ::v-deep .el-select__input {
      margin-left: 32px !important;
    }
    .nav-menu {
      display: flex;
      align-items: center;
      gap: 14px;
      flex: 1;
      justify-content: flex-end;
      margin-right: 40px;

      .menu-item {
        padding: 3px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: #e7e7e7;
        }
        img {
          width: 19px;
        }
      }

      // justify-content: flex-end;
    }
    .user-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      .el-icon-arrow-down {
        font-weight: bold;
      }
    }
  }

  // .right-menu {
  //   text-align: right;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-end;
  //   gap: 10px;
  //   font-size: 14px;
  //   flex: 0 0 auto;

  //   .user-name {
  //     color: rgba(0, 0, 0, 0.8);
  //   }

  //   .right-menu-bottom {
  //     span {
  //       padding-left: 16px;
  //     }
  //     .user-msg {
  //       padding-left: 0;

  //       &.red {
  //         color: rgba(194, 15, 15, 0.9);
  //         border-bottom: 1px solid rgba(194, 15, 15, 0.9);
  //       }
  //     }
  //   }
  // }
}
</style>
