<template>
  <div class="x-notice" v-if="show">
    <div class="notice-container">
      <div class="notice-header">
        <div class="header-title">
          {{ notice.type == "message" ? "消息" : "公告" }}
        </div>
      </div>
      <div class="notice-main">
        <div class="notice-title">
          {{ notice.title }}
        </div>
        <div class="notice-content" v-html="notice.content"></div>
        <div class="justify-center">
          <button class="search-button submit-btn" @click="confirm">
            确定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { messageEditRead } from "@/api/user";

export default {
  data() {
    return {
      show: false,
      notice: {
        title: "",
        content: "",
        type: "notice",
      },
      callback: () => {},
    };
  },
  methods: {
    open(value, callback) {
      if (!value) return;
      this.show = true;
      this.notice = value;
      this.callback = callback;
    },
    async confirm() {
      console.log(this.notice);
      let data = {
        notice_id: this.notice.id,
        admin_id: this.$store.getters.userInfo.id,
      };
      try {
        if (this.callback && typeof this.callback == "function") {
          this.callback && this.callback();
        } else {
          await messageEditRead(data);
          this.$store.dispatch("messageUnread");
        }
        this.show = false;
        this.notice = {};
      } catch (err) {
        this.$message.error(err.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.x-notice {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2009;
  overflow: hidden;
  //   padding: 0 324px;

  .notice-container {
    width: 792px;
    max-width: 90%;
    max-height: 80%;
    // min-height: 388px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    animation: show 0.3s ease-in-out;
    animation-fill-mode: forwards;
    background: #ffffff;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 6px 30px 5px rgba(0, 0, 0, 0.05);

    .notice-header {
      font-size: 14px;
      padding: 12px 24px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    .notice-main {
      flex: 1;
      overflow: auto;
      //   background-color: red;
      padding: 24px 107px 40px 107px;

      .notice-title {
        color: black;
        text-align: center;
      }

      .notice-content {
        margin-top: 40px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.6);
        text-wrap: wrap;
        font-size: 14px;

        img {
          max-width: 100%;
        }
      }
      .submit-btn {
        margin-top: 40px;
      }
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
