import store from "@/store";

export default {
  inserted(el, binding) {
    let key = binding.value?.key;
    let value = binding.value?.value;
    if (store.getters.userInfo.id == 1) return;
    if (key && value == 0) {
      el.classList.remove("enable");
      el.classList.add("disabled");
      el.addEventListener(
        "click",
        (event) => {
          event && event.stopImmediatePropagation();
        },
        true
      );
    }
  },
};
